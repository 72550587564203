import {css} from 'styled-components'

import theme from './theme'

import arrow from '../images/icons/arrow-right.svg'
import arrowWhite from '../images/icons/arrow-right--white.svg'

const global = css`
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  *::before,
  *::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    font-family: ${props => props.theme.font.primary};
    color: ${props => props.theme.color.text.base};
    background: ${props => props.theme.color.primary.base};
  }

  body {
    font-size: 1.8rem;
    line-height: 1.4;
    font-weight: 400;
    font-style: normal;
    background: ${props => props.theme.color.background.base};
  }

  img {
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  .link {
    &--inline {
      font-weight: ${theme.weights.bold};
      border-bottom: 2px solid ${theme.color.primary.base};
      color: ${theme.color.primary.base};
      transition: all 0.1s;

      &:hover {
        color: ${theme.color.primary.base};
        background: ${theme.color.accent};
      }
    }

    &--inline--secondary {
      font-weight: ${theme.weights.bold};
      border-bottom: 2px solid ${theme.color.secondary.base};
      color: ${theme.color.secondary.base};
      transition: all 0.1s;

      &:hover {
        color: ${theme.color.secondary.base};
        background: ${theme.color.secondary.lightest};
      }
    }

    &--hover--underline {
      &:hover {
        border-bottom: 1px solid currentColor;
      }
    }
  }

  h1 {
    font-size: 3.2rem;
    line-height: 1.2;
    letter-spacing: -0.02em;

    @media (min-width: ${theme.breakpoints.lg}) {
      font-size: 4rem;
    }

    @media (min-width: ${theme.breakpoints.xl}) {
      font-size: 4.4rem;
    }
  }

  h2 {
    font-size: 2.8rem;
    line-height: 1.3;
    letter-spacing: -0.02em;

    @media (min-width: ${theme.breakpoints.lg}) {
      font-size: 3.4rem;
    }

    @media (min-width: ${theme.breakpoints.xl}) {
      font-size: 3.6rem;
    }
  }

  h3 {
    font-size: 2.3rem;
    line-height: 1.25;
    letter-spacing: -0.02em;

    @media (min-width: ${theme.breakpoints.lg}) {
      font-size: 2.5rem;
    }
  }

  p {
    font-size: 1.6rem;

    @media (min-width: ${theme.breakpoints.lg}) {
      font-size: 1.8rem;
    }
  }

  em {
    font-style: italic;
  }

  small {
    font-size: 80%;
  }

  strong {
    font-weight: 500;
  }

  .visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }

  hr {
    margin: 0;
    border: 0;
    border-top: 1px solid ${theme.color.grey.base};
  }

  .margin {
    &--large {
      &--top {
        margin-top: 60px;
      }
      @media (min-width: ${theme.breakpoints.lg}) {
        &--top {
          margin-top: 70px;
        }
      }
    }

    &--medium {
      &--top {
        margin-top: 50px;
      }

      &--bottom {
        margin-bottom: 50px;
      }
    }
  }

  /* Buttons ------------------------------------ */

  .button--round {
    height: 55px;
    border: 2px solid ${theme.color.primary.base};
    border-radius: 27.5px;
    display: inline-flex;
    align-items: center;
    color: ${theme.color.primary.base};
    font-weight: ${theme.weights.bold};
    padding: 0 24px;
    font-size: 1.8rem;

    @media (min-width: ${theme.breakpoints.lg}) {
      font-size: 2rem;
      height: 60px;
      padding: 0 30px;
      border-radius: 30px;
    }

    &--with-arrow {
      &::after {
        content: '';
        display: inline-block;
        width: 25px;
        height: 25px;
        margin: 0 -5px 0 10px;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        left: 0;
        background-image: url(${arrow});
        transition: left 0.2s ease-in-out;
      }

      &:hover::after {
        left: 4px;
      }

      @media (min-width: ${theme.breakpoints.lg}) {
        &::after {
          margin: 0 -5px 0 15px;
        }
      }
    }

    &--inverted {
      color: ${theme.color.inverted};
      border-color: ${theme.color.inverted};

      &::after {
        background-image: url(${arrowWhite});
      }
    }
  }
`

export default global
