import styled from 'styled-components'

const GridCanvas = styled.div`
  display: grid;
  grid-template-columns:
    [full-start]
    minmax(25px, auto)
    [wide-start]
    minmax(auto, 300px)
    [main-start]
    calc(min(750px, 100% - 50px))
    [main-end]
    minmax(auto, 300px)
    [wide-end]
    minmax(25px, auto)
    [full-end];

  > * {
    grid-column: main-start / main-end;
  }
`

export default GridCanvas
