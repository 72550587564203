import styled from 'styled-components'
import {Link} from 'gatsby'

import arrow from '../images/icons/arrow-right.svg'

const ReverseArrowLink = styled(Link)`
  font-size: 1.8rem;
  color: ${props => props.theme.color.primary.base};
  background: none;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 500;

  &::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(${arrow});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    left: 0;
    transform: rotate(180deg);
    transition: left 0.1s ease-in-out;
    margin-right: 6px;
    top: -1px;
  }

  &:hover {
    color: ${props => props.theme.color.primary.base};
    &::before {
      left: -2px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 2rem;

    &::before {
      width: 22px;
      height: 22px;
    }
  }
`

export default ReverseArrowLink
