import React from 'react'
import styled from 'styled-components'

import Arrow from '../images/inline-svgs/arrow-right.svg'

const NewsletterSubscribeForm = () => (
  <Form name="newsletter" method="POST" data-netlify="true">
    <input type="hidden" name="form-name" value="newsletter" />
    <Fieldset>
      <label htmlFor="newsletter-email" className="visually-hidden">
        Your email
      </label>
      <Input
        type="email"
        placeholder="Your email"
        id="newsletter-email"
        name="email"
        required
      />
      <Submit type="submit" aria-label="Subscribe">
        <Arrow stroke="white" />
      </Submit>
    </Fieldset>
  </Form>
)

export default NewsletterSubscribeForm

const Form = styled.form`
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 300px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    max-width: 310px;
  }
`

const Fieldset = styled.fieldset`
  height: 47px;
  display: flex;
  border-radius: 6px;
  overflow: hidden;
`

const Input = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  padding: 10px 12px;
  font-size: 1.5rem;
  color: ${props => props.theme.color.text.base};
`

const Submit = styled.button`
  background: ${props => props.theme.color.secondary.base};
  height: 100%;
  display: flex;
  width: 100%;
  max-width: 45px;
  align-items: center;
  justify-content: center;
`
