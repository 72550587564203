import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

import {BoxedContainer, TitleUppercaseDiv} from '../elements'
import NewsletterSubscribeForm from './NewsletterSubscribeForm'

import Logo from '../images/logos/logo-white.svg'
import Twitter from '../images/inline-svgs/twitter.svg'
import Dribbble from '../images/inline-svgs/dribbble.svg'

const Footer = () => (
  <Wrapper>
    <BoxedContainer>
      <Top>
        <Branding>
          <SiteLogo src={Logo} alt="Stylesheets.dev"></SiteLogo>
          <SubscribeText>
            Subscribe to receive a monthly newsletter with the latest updates
            and posts.
          </SubscribeText>
          <NewsletterSubscribeForm />
        </Branding>
        <Nav aria-label="Site">
          <Column>
            <TitleUppercaseDiv>Products &amp; Services</TitleUppercaseDiv>
            <ul>
              <li>
                <Link to="/themes/ghost/">Ghost Themes</Link>
              </li>
              <li>
                <Link to="/hire-us/">Hire us</Link>
              </li>
            </ul>
          </Column>
          <Column>
            <TitleUppercaseDiv>Company</TitleUppercaseDiv>
            <ul>
              <li>
                <Link to="/blog/">Blog</Link>
              </li>
              <li>
                <a href="https://docs.stylesheets.dev">Documentation</a>
              </li>
              <li>
                <Link to="/showcase/">Showcase</Link>
              </li>
              {/* <li>
                <Link to="/faq/">FAQs</Link>
              </li> */}
              <li>
                <Link to="/contact/">Contact us</Link>
              </li>
            </ul>
          </Column>
          <Column>
            <TitleUppercaseDiv>Connect</TitleUppercaseDiv>
            <ul>
              <li>
                <a
                  href="https://twitter.com/stylesheetsdev"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://dribbble.com/Stylesheetsdev"
                  target="_blank"
                  rel="noreferrer"
                >
                  Dribbble
                </a>
              </li>
              <li>
                <a
                  href="mailto:support@stylesheets.dev"
                  target="_blank"
                  rel="noreferrer"
                >
                  Email
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/StylesheetsDev"
                  target="_blank"
                  rel="noreferrer"
                >
                  Github
                </a>
              </li>
            </ul>
          </Column>
        </Nav>
      </Top>
      <Bottom>
        <Copyright>Stylesheets.dev © 2019-{new Date().getFullYear()}</Copyright>
        <Icons>
          <Icon
            href="https://twitter.com/stylesheetsdev"
            target="_blank"
            rel="noreferrer"
            title="Twitter"
          >
            <Twitter fill="white" />
          </Icon>
          <Icon
            href="https://dribbble.com/Stylesheetsdev"
            target="_blank"
            rel="noreferrer"
            title="Dribbble"
          >
            <Dribbble fill="white" />
          </Icon>
        </Icons>
      </Bottom>
    </BoxedContainer>
  </Wrapper>
)

export default Footer

const Wrapper = styled.footer`
  background-color: ${props => props.theme.color.primary.base};
  color: ${props => props.theme.color.inverted};
`

/* Top */
const Top = styled.div`
  padding: 40px 0;

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    display: flex;
    justify-content: space-between;
    padding: 60px 0;
  }
`

/* Branding */
const Branding = styled.div`
  margin: 0 0 40px;
`

const SiteLogo = styled.img`
  height: 45px;
  margin: 0 0 15px;
`

const SubscribeText = styled.p`
  font-size: 1.5rem;
  margin: 0 0 15px;
  opacity: 0.85;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 300px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    max-width: 310px;
    font-size: 1.6rem;
  }
`

/* Navigation */
const Nav = styled.nav`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-gap: 30px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    grid-gap: 60px;
  }
`

const Column = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    font-size: 1.6rem;
    margin: 0 0 6px;

    a {
      color: ${props => props.theme.color.inverted};

      &:hover {
        text-decoration: underline;
      }
    }

    &:last-child {
      margin: 0;
    }
  }

  ${TitleUppercaseDiv} {
    margin: 0 0 12px;
    color: ${p => p.theme.color.inverted};
  }
`

/* Bottom */
const Bottom = styled.div`
  border-top: 2px solid rgba(255, 255, 255, 0.1);
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Copyright = styled.p`
  font-size: 1.5rem;
  opacity: 0.75;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 1.6rem;
  }
`

const Icons = styled.div`
  display: flex;
`

const Icon = styled.a`
  width: 25px;
  height: 25px;
  opacity: 0.8;
  margin: 0 0 0 4px;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`
