import styled, {css} from 'styled-components'

export const TitleUppercaseStyle = css`
  font-size: ${p => p.large || p.xlarge || '1.4rem'};
  margin: 0;
  text-transform: uppercase;
  opacity: 0.7;
  letter-spacing: 0.05em;
  line-height: 1.4;
  font-weight: 500;
  text-align: ${p => (p.center ? 'center' : 'initial')};
  color: ${p => p.color || p.theme.color.text.lighter};
`

const TitleUppercase = styled.h2.attrs(props => ({
  large: props.large ? '1.6rem' : null,
  xlarge: props.xlarge ? '1.7rem' : null,
}))`
  ${TitleUppercaseStyle}
`

export const TitleUppercaseDiv = styled.div`
  ${TitleUppercaseStyle}
`

export default TitleUppercase
