import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'

import {Subtitle} from './Subtitle'

const Section = styled.section`
  background-color: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.color.background.base};
  ${props =>
    props.padding === 'both' &&
    css`
      padding: 60px 0;

      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        padding: 70px 0;
      }
    `}
  ${props =>
    props.padding === 'bottom' &&
    css`
      padding: 0 0 60px;

      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        padding: 0 0 70px;
      }
    `}
    ${props =>
    props.padding === 'fixed' &&
    css`
      padding: 70px 0;
    `};
`

Section.propTypes = {
  padding: PropTypes.oneOf(['top', 'bottom', 'both', 'fixed']),
  backgroundColor: PropTypes.string,
}

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 70px;

  h2 {
    color: ${props => props.theme.color.primary.base};
    margin: 0 20px 0 0;
  }

  ${Subtitle} {
    margin: 4px 0 0;
  }
`

const SectionHeaderCenterAligned = styled.div`
  text-align: center;
  margin: 0 0 70px;
  max-width: 700px;

  h2 {
    color: ${props => props.theme.color.primary.base};
    margin: 0 0 20px;
  }

  ${Subtitle} {
    margin: 0;
  }
`

const SectionFooter = styled.div`
  margin: 60px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export {Section, SectionHeader, SectionHeaderCenterAligned, SectionFooter}
