const theme = {
  color: {
    primary: {
      lightest: '#E2DCF1',
      lighter: '#4a3681',
      base: '#3A2A67',
    },
    secondary: {
      lightest: '#ecf5ff',
      base: '#52A2F4',
    },
    accent: '#F4DEFF',
    background: {
      base: '#FFF',
      light: '#F9F8FF', // Super light shade of primary color
      lightgrey: '#f8f8f8',
    },
    inverted: '#FFF',
    grey: {
      lighter: '#F8F8F8',
      base: '#F2F2F2',
      darker: '#D8D8D8',
      darkest: '#959498',
    },
    text: {
      darker: '#333',
      base: '#444',
      lighter: '#555',
      lightest: '#777',
    },
    green: '#4BB543',
  },
  font: {
    primary:
      'Karla, -apple-system, "BlinkMacSystemFont", "Segoe UI", "Helvetica Neue", sans-serif',
  },
  weights: {
    normal: '400',
    bold: '500',
    xbold: '700',
  },
  breakpoints: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1400px',
  },
  shadows: {
    light: '0px 1px 2px 0px rgb(0 0 0 / 20%)',
  },
  radius: {
    md: '4px',
    lg: '6px',
    xl: '8px',
  },
}

export default theme
