import styled, {css} from 'styled-components'

const SubtitleStyle = css`
  font-size: 1.8rem;
  line-height: 1.6;
  opacity: 0.8;

  @media (min-width: ${p => p.theme.breakpoints.lg}) {
    font-size: 2rem;
  }
`

const Subtitle = styled.p`
  ${SubtitleStyle}
  margin: ${p => p.margin || '0px'};
`

const SubtitleLarge = styled.p`
  font-size: 2rem;
  line-height: 1.6;
  margin: ${p => p.margin || '0px'};
  color: ${p => p.theme.color.text.lighter};

  strong {
    color: ${p => p.theme.color.text.base};
  }

  @media (min-width: ${p => p.theme.breakpoints.lg}) {
    font-size: 2rem;
  }
`

export {Subtitle, SubtitleLarge}
