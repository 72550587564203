import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

const StyledNavItem = styled.li`
  margin-left: 18px;
  font-size: 1.8rem;

  a {
    color: ${props => props.theme.color.inverted};
    text-decoration: none;
    display: block;
    position: relative;
    line-height: 1.8;

    &::after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 0;
      height: 2px;
      background: ${props => props.theme.color.inverted};
      transition: width 0.2s ease-in-out;
    }

    &:hover::after {
      width: 100%;
    }

    &.nav-item--active::after {
      width: 100%;
    }
  }
`

const NavItem = ({children, path, external}) => (
  <StyledNavItem>
    {external ? (
      <a href={path}>{children}</a>
    ) : (
      <Link to={path} activeClassName="nav-item--active" partiallyActive={true}>
        {children}
      </Link>
    )}
  </StyledNavItem>
)

export default NavItem
