import styled, {css} from 'styled-components'
import {Link} from 'gatsby'

import arrow from '../images/icons/arrow-right.svg'

export const ArrowLinkStyles = css`
  font-size: 1.6rem;
  color: ${props => props.theme.color.primary.base};
  background: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &::after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(${arrow});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-left: 6px;
    position: relative;
    left: 0;
    transition: left 0.1s ease-in-out;
  }

  &:hover {
    color: ${props => props.theme.color.primary.base};
    &::after {
      left: 2px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 1.8rem;
  }
`

const ArrowLink = styled(Link)`
  ${ArrowLinkStyles}
`

export default ArrowLink
