import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

const StyledNavItem = styled.li`
  font-size: 1.8rem;

  a {
    padding: 15px 20px 7px;
    color: ${props => props.theme.color.primary.base};
    text-decoration: none;
    display: block;
    position: relative;
    line-height: 1.8;
    width: 100%;

    &.nav-item--active {
      background: ${props => props.theme.color.grey.base};
      padding: 15px 20px;
    }
  }
`

const HamburgerNavItem = ({children, path, external}) => (
  <StyledNavItem>
    {external ? (
      <a href={path}>{children}</a>
    ) : (
      <Link to={path} activeClassName="nav-item--active">
        {children}
      </Link>
    )}
  </StyledNavItem>
)

export default HamburgerNavItem
