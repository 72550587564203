import styled from 'styled-components'

const ShowOnMobile = styled.div`
  @media (min-width: ${p => p.theme.breakpoints.md}) {
    display: none;
  }
`

const HideOnMobile = styled.div`
  display: none;
  @media (min-width: ${p => p.theme.breakpoints.md}) {
    display: block;
  }
`

export {ShowOnMobile, HideOnMobile}
