import React from 'react'
import PropTypes from 'prop-types'
import {ThemeProvider, createGlobalStyle} from 'styled-components'

import theme from '../styles/theme'
import reset from '../styles/reset'
import global from '../styles/global'
import Header from './Header'
import Footer from './Footer'

import '@fontsource/karla/400.css'
import '@fontsource/karla/400-italic.css'
import '@fontsource/karla/500.css'
import '@fontsource/karla/700.css'

const GlobalStyles = createGlobalStyle`
  ${reset}
  ${global}
`

const Layout = ({children}) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header />
      <main id="#main">{children}</main>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}
