import styled from 'styled-components'

const ThemeCardsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 60px 25px;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
  }
`

export default ThemeCardsGrid
