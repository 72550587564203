import styled from 'styled-components'

const BoxedContainer = styled.div.attrs(props => ({
  narrow: props.narrow ? '700px' : null,
  medium: props.medium ? '1240px' : null,
  wide: props.wide ? '1575px' : null,
}))`
  width: 100%;
  max-width: ${props => props.narrow || props.medium || props.wide || '1400px'};
  padding: 0 25px;
  margin: 0 auto;
`

export default BoxedContainer
