import styled from 'styled-components'
import {Link} from 'gatsby'

import arrowWhite from '../images/icons/arrow-right--white.svg'

const ViewMoreButton = styled(Link)`
  font-size: 1.6rem;
  color: ${props => props.theme.color.text.lightest};
  background: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &::after {
    content: '';
    display: block;
    width: 45px;
    height: 45px;
    background-image: url(${arrowWhite});
    background-repeat: no-repeat;
    background-size: cover;
    background-color: ${props => props.theme.color.primary.base};
    background-size: 19px;
    border-radius: 100%;
    background-position: center;
    margin-left: 10px;
    position: relative;
    left: 0;
    transition: left 0.1s ease-in-out;
  }

  &:hover {
    color: ${props => props.theme.color.text.lighter};
    &::after {
      left: 2px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 1.8rem;

    &::after {
      width: 58px;
      height: 58px;
      background-size: 22px;
    }
  }
`

export default ViewMoreButton
