import React, {useState, useEffect} from 'react'
import styled, {css} from 'styled-components'
import {Link} from 'gatsby'

import BoxedContainer from '../../elements/BoxedContainer'
import NavItem from './NavItem'
import HamburgerNavItem from './HamburgerNavItem'

// import logo from '../../images/logos/logo-white.svg'
import SiteLogo from '../../images/inline-svgs/logo-white.svg'

const Header = () => {
  const [isDrawerOpen, toggleDrawer] = useState(false)
  const [isHeaderFixed, setHeaderFixed] = useState(false)

  useEffect(() => {
    let makeHeaderSticky = function () {
      let breakpoint = 0
      if (
        document.body.scrollTop > breakpoint ||
        document.documentElement.scrollTop > breakpoint
      ) {
        setHeaderFixed(true)
      } else {
        setHeaderFixed(false)
      }
    }
    window.addEventListener('scroll', makeHeaderSticky)
    return () => {
      window.removeEventListener('scroll', makeHeaderSticky)
    }
  }, [])

  return (
    <Wrapper>
      <Container isHeaderFixed={isHeaderFixed}>
        <BoxedContainer>
          <Nav aria-label="Primary">
            <Logo to="/" active={isHeaderFixed}>
              {/* <img
                src={logo}
                alt="Stylesheets.dev"
                style={{
                  height: isHeaderFixed ? '40px' : '45px',
                }}
              /> */}
              <SiteLogo />
            </Logo>

            <NavItems>
              <NavItem path="/themes/ghost/">Themes</NavItem>
              <NavItem path="https://docs.stylesheets.dev" external>
                Docs
              </NavItem>
              <NavItem path="/blog/">Blog</NavItem>
              <NavItem path="/hire-us/">Hire us</NavItem>
              <NavItem path="/contact/">Contact</NavItem>
            </NavItems>

            <Hamburger
              onClick={() => toggleDrawer(!isDrawerOpen)}
              className={isDrawerOpen ? 'open' : ''}
              aria-label="menu"
            >
              <span></span>
            </Hamburger>
          </Nav>

          <HamburgerNav active={isDrawerOpen}>
            <ul>
              <HamburgerNavItem path="/themes/ghost/">Themes</HamburgerNavItem>
              <HamburgerNavItem path="https://docs.stylesheets.dev" external>
                Docs
              </HamburgerNavItem>
              <HamburgerNavItem path="/blog/">Blog</HamburgerNavItem>
              <HamburgerNavItem path="/hire-us/">Hire us</HamburgerNavItem>
              <HamburgerNavItem path="/contact/">Contact</HamburgerNavItem>
            </ul>
          </HamburgerNav>
        </BoxedContainer>
      </Container>
    </Wrapper>
  )
}

export default Header

const Wrapper = styled.header`
  position: relative;
  height: 90px;
  background-color: ${props => props.theme.color.primary.base};
`

const Container = styled.div`
  background-color: ${props => props.theme.color.primary.base};
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  transition: all 0.2s;
  height: 90px;
  display: flex;
  align-items: center;

  ${props =>
    props.isHeaderFixed &&
    css`
      position: fixed;
      top: 0;
      width: 100%;
      height: 63px;
    `}
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 25px 0; */
`

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  transition: height 0.2s;
  height: ${p => (p.active ? '40px' : '45px')};

  svg {
    width: auto;
    height: 100%;
  }
`

const NavItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    display: flex;
    align-items: center;
  }
`

const Hamburger = styled.button`
  position: relative;
  width: 30px;
  height: 35px;
  background: none;
  padding: 0;
  margin: 13px 0 0;

  span {
    position: absolute;
    cursor: pointer;
    height: 2px;
    width: 30px;
    background: ${props => props.theme.color.inverted};
    display: block;
    content: '';
    top: 0;
    transition: all 0.2s ease;

    &::before,
    &::after {
      cursor: pointer;
      height: 2px;
      width: 30px;
      background: ${props => props.theme.color.inverted};
      position: absolute;
      display: block;
      content: '';
      transition: all 0.2s ease;
      top: 0;
    }

    &::after {
      top: 16px;
    }

    &::before {
      top: 8px;
    }
  }

  &.open {
    span {
      transform: rotate(45deg);
      top: 10px;

      &::before {
        background-color: transparent;
      }

      &::after {
        transform: rotate(90deg);
        top: 0;
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
`

const HamburgerNav = styled.nav`
  position: fixed;
  z-index: 100;
  transform: ${props =>
    props.active ? `translateX(0%)` : `translateX(-150%)`};
  transition: 400ms;
  background: white;
  top: 0;
  height: 100vh;
  width: 60vw;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  visibility: ${props => (props.active ? `visible` : `hidden`)};
  left: 0;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`
