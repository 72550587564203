import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'

const normal = {
  mobile: '60px',
  desktop: '70px',
}

const small = {
  mobile: '40px',
}

const Padding = styled.div`
  ${props =>
    props.topBottom &&
    css`
      padding-top: 60px;
      padding-bottom: 60px;

      @media (min-width: ${props => props.theme.breakpoints.lg}) {
        padding-top: 70px;
        padding-bottom: 70px;
      }
    `}

  ${props =>
    props.bottom &&
    props.small &&
    css`
      padding-bottom: ${small.mobile};
    `}

  ${props =>
    props.top &&
    props.small &&
    css`
      padding-top: ${small.mobile};
    `}
`

Padding.propTypes = {
  topBottom: PropTypes.bool,
  bottom: PropTypes.bool,
  small: PropTypes.bool,
}

export default Padding
